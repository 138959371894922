<template>
    <div>

        <div class="container  titleContainer">
            <div class="row">
                <div class="col-md-5 col-sm-7 createCardTitleWrapper">
                    <b-card id="createCardTitle">
                        <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="none" to="/marketplace/manage"
                            v-b-tooltip.hover title="Go Back to the Script List" class="d-inline text-warning">
                            <feather-icon icon="ArrowLeftCircleIcon" size="25" />
                        </b-button>
                        <h3 class="d-inline text-center font-weight-bolder">ADD A NEW SCRIPT</h3>
                    </b-card>
                </div>
            </div>
        </div>
        <b-card id="createCardForm">
            <div class="container">
                <form @submit.prevent="storeCode">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <b-form-group label="Name">
                                <b-form-input v-model="name" required
                                    placeholder="Enter a name for the script"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Image URL">
                                <b-form-file v-model="image" required placeholder="Choose an image"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-12">
                            <b-form-group label="Description">
                                <b-form-textarea v-model="description" rows="4" required
                                    placeholder="Enter a description"></b-form-textarea>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Screenshots">
                                <b-form-file v-model="screens" multiple accept=".png,.jpg,.jpeg"
                                    placeholder="Choose screenshots"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="ZIP File">
                                <b-form-file v-model="zipFiles" accept=".rar,.zip"
                                    placeholder="Choose a ZIP file"></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="tags-basic">Type a new tag and press enter</label>
                            <b-form-tags input-id="tags-basic" v-model="tags"></b-form-tags>
                        </div>
                        <div class="col-md-3">
                            <b-button type="submit" variant="outline-success" block>Submit</b-button>
                        </div>
                    </div>
                </form>
            </div>

        </b-card>
    </div>
</template>

<script>
import {
    BContainer,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BCard,
    BRow,
    BCol,
    VBTooltip,
    BButtonGroup,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BLink,
    BButton,
    BModal,
    VBModal,
    BFormFile,
    BFormTextarea,
    BCardText,
    BFormTags,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
    components: {
        BTable,
        BFormTextarea,
        BAvatar,
        BLink,
        'b-tooltip': VBTooltip,
        BCard,
        BFormTags,
        BImg,
        BButtonGroup,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BFormFile,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        VBModal,
        BCardText,
        BContainer,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            name: '',
            image: null,
            description: '',
            screens: [],
            zip_url: '',
            zipFiles: null,
            tags: [],
        }
    },
    computed: {

    },
    mounted() {
      const data = getAccessTokenFromCookie();
        const userType = data[2].role;
        if (userType !== "admin") {
            router.push({ name: "error-404" })
        }

    },
    created() {
    },
    methods: {
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        storeCode() {
            try {
                var screensFiles = [];
                if (this.screens) {
                    for (let i = 0; i < this.screens.length; i++) {
                        screensFiles.push(this.screens[i]);
                    }
                }

                var formData = new FormData();
                formData.append("name", this.name);
                formData.append("image_url", this.image);
                formData.append("description", this.description);
                formData.append("user_token", this.user_token);
                formData.append("tags[]", this.tags);
                for (let i = 0; i < screensFiles.length; i++) {
                    formData.append("screens[]", screensFiles[i]);
                }
                formData.append("zip_urls", this.zipFiles);

                axios.post('/add-mrktp-scripts', formData)
                    .then(({ data }) => {
                        if (data.status == "success") {
                            this.showToast(
                                "success",
                                "SaveIcon",
                                "Success",
                                data.message
                            );
                            router.push({ name: 'adminMarketplace' })
                        } else if (data.status == "failed") {
                            this.showToast(
                                "danger",
                                "AlertTriangleIcon",
                                "Error",
                                data.message
                            );
                        }
                    })
                    .catch((error) => {
                        this.showToast(
                            "danger",
                            "AlertTriangleIcon",
                            "Error",
                            error
                        );
                    });
            } catch (error) {
                console.error('Error uploading files:', error);
            }

        }

    },
    watch: {

    },
}
</script>

<style scoped>
#createCardTitle,
#createCardForm,
.createCardTitleWrapper,
[dir] .card,
.titleContainer {
    margin: 0 !important;

}



#createCardTitle,
#createCardForm {
    border-radius: 0 !important;
}

div.app-content.content>div.content-wrapper>div>div>div.container.titleContainer>div>div {
    padding: 0;
}
</style>